
import { defineComponent } from "vue";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import EntityModal from "@/components/modals/forms/entity/EntityModal.vue";
// import * as Yup from "yup";
import ApiService from "@/core/services/ApiService";

export default defineComponent({
  name: "new-entity-course",
  components: {
    EntityModal,
    Datatable,
  },
  data() {
    return {
      batch: {},
      employee: {},
      details: {},
      weekplan: {},
      entityTypes: [],
      traineeInfos: [],
      divisions: [],
      districts: [],
      subDistricts: [],
      empDistricts: [],
      tableHeader: [
        {
          name: "Actions",
          key: "actions",
          sortable: false,
          width: "150px",
        },
        {
          name: "Photo",
          key: "photo",
          sortable: true,
        },
        {
          name: "Trainee Name",
          key: "trainee_name",
          sortable: true,
        },
        {
          name: "Training Istitute",
          key: "training_institute",
          sortable: true,
        },
        {
          name: "Course",
          key: "course",
          sortable: true,
        },
        {
          name: "Ref No",
          key: "ref_no",
          sortable: true,
        },
        {
          name: "Email",
          key: "email",
          sortable: true,
        },
        {
          name: "Phone",
          key: "phone",
          sortable: true,
        },
        {
          name: "Address",
          key: "address",
          sortable: true,
        },
        {
          name: "Birth Date",
          key: "birth_date",
          sortable: true,
        },

        {
          name: "Gender",
          key: "gender",
          sortable: true,
        },
        {
          name: "Status",
          key: "status",
          sortable: true,
        },
      ],
      loading: false,
      courseInfoData: false,
      showCourseNotice: false,
      showtrainingProviderNotice: false,
    };
  },
  async created() {
    await this.getEntityTypes();
    await this.getPendingTraineeInfos();
    this.emitter.on("infos-updated", async () => {
      await this.getPendingTraineeInfos();
    });
  },
  methods: {
    async formSubmit() {
      //form submit
    },
    async getPendingTraineeInfos() {
      await ApiService.get("trainee/pending_trainee")
        .then((response) => {
          this.traineeInfos = response.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getEntityTypes() {
      await ApiService.get("entity/types")
        .then((response) => {
          this.entityTypes = response.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    courseInfo() {
      this.courseInfoData = true;
    },
    courseNotice() {
      this.showCourseNotice = true;
    },
    trainingProviderNotice() {
      this.showtrainingProviderNotice = true;
    },
    edit(data) {
      this.emitter.emit("edit-modal-data", data);
    },

    add() {
      this.emitter.emit("add-modal-data", true);
    },
    Approve(id) {
      let data = {
        trainee_id: id,
        status: 1,
      };
      Swal.fire({
        title: "Are you sure you want to approve it?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Approved!",
      }).then((result) => {
        if (result.isConfirmed) {
          ApiService.post("trainee/approve", data)
            .then((response) => {
              this.emitter.emit("infos-updated", true);
              Swal.fire("Approved!", response.data.message, "success");
            })
            .catch(({ response }) => {
              console.log(response);
            });
        }
      });
    },
    Reject(id) {
      let data = {
        trainee_id: id,
        status: -1,
      };
      Swal.fire({
        title: "Are you sure you want to reject it?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Reject!",
      }).then((result) => {
        if (result.isConfirmed) {
          ApiService.post("trainee/reject", data)
            .then((response) => {
              this.emitter.emit("infos-updated", true);
              Swal.fire("Rejected!", response.data.message, "success");
            })
            .catch(({ response }) => {
              console.log(response);
            });
        }
      });
    },
  },
  setup() {
    const AssociationSchema = [];
    return {
      AssociationSchema,
    };
  },
});
